import { RGB } from '@10x/foundation/src/components/color-picker/ColorPicker';
import { Topic, TopicType } from './Topic';

import {
  classNames,
  getHeightWidthFromTailwindCn,
} from '@foundationPathAlias/utilities';
import Image from 'next/image';

const communityLogoDm = require('../../../assets/images/icons/community_logo_dm.png');
const communityLogoLm = require('../../../assets/images/icons/community_logo_lm.png');

export type CommunityColor = {
  dark: RGB;
  light: RGB;
};

export type CommunityCardPropsType = {
  name: string | undefined;
  description: string | void | null;
  topics: TopicType[];
  active?: boolean;
  imageSrc: string | void;
  logoSrc?: string | void;
  membersCount: number | undefined | string;
  // rgb object from store
  communityColor: RGB;
  logoAlt?: string;
  isDarkMode?: boolean;
  onClick?: () => void;
  cn?: string;
  loadImageOnPriority?: boolean;
};

// export type CommunityCardPropsType = {
//   imgEl: JSX.Element;
//   src: string;
// };

export function CommunityCard(props: CommunityCardPropsType) {
  const defaultCommunityLogo = props.isDarkMode
    ? communityLogoDm
    : communityLogoLm;

  const {
    name = '',
    description = '',
    topics,
    imageSrc,
    logoSrc = defaultCommunityLogo,
    active,
    communityColor,
    membersCount = 0,
    onClick,
    logoAlt = 'Community card bg alt',
    cn,
    loadImageOnPriority = false,
  } = props;

  let finalTopics = null;
  if (topics && topics.length) {
    finalTopics = topics.map((topic) => (
      <Topic
        active={active}
        name={topic.name}
        key={topic.id}
        color={communityColor}
      />
    ));
  }

  const members = membersCount ? `${membersCount} member` : 'No members yet';
  const { height = 44, width = 44 } = getHeightWidthFromTailwindCn(cn) || {};
  return (
    <div
      className={classNames(
        'relative h-[288px] w-full flex-shrink-0 rounded-[20px] md:flex-shrink bigheight:h-[380px]',
        !imageSrc ? 'bg-element-normal dark:bg-element-normal-dark' : '',
        cn
      )}
      onClick={onClick}
    >
      {typeof imageSrc === 'string' && (
        <Image
          src={imageSrc || ''}
          alt={name}
          fill
          className="rounded-[20px]"
          priority={loadImageOnPriority}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      )}
      {/* {imgEl} */}
      <div className="community-card-body absolute flex h-full w-full cursor-pointer items-end rounded-[16px]  p-[8px] ">
        <div
          className="community-card-caption-el flex h-full max-h-[194px] w-full cursor-pointer flex-col rounded-[16px] bg-surface p-[12px] text-left dark:bg-surface-dark"
          // onClick={onClick}
        >
          <div className="relative flex flex-1 flex-col pt-[12px]">
            <div className="absolute -top-[36px] left-0 h-[44px] w-[44px] overflow-hidden rounded-[8px] border-[3px] border-background-primary bg-background-secondary dark:bg-background-secondary-dark">
              {typeof logoSrc === 'string' && (
                <Image
                  width={width}
                  height={height}
                  src={logoSrc}
                  alt={logoAlt}
                />
              )}
            </div>
            <h5 className="themed-text mb-[2px] overflow-hidden overflow-ellipsis text-sm14SB font-extrabold">
              {name}
            </h5>
            <p className="line-clamp-3 max-h-[80px] flex-1 text-sm14R dark:text-text-secondary-dark">
              {description}
            </p>
            <div className="my-[8px] ">
              <div className="min-h-[24px]">
                <span className="block h-[28px] w-full space-x-[8px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                  {finalTopics}
                </span>
              </div>
            </div>
            <span className="exstraSm12R text-text-secondary-1 dark:text-text-secondary-1-dark ">
              {members}
            </span>
          </div>
          {/* <div className="community-card-caption-bottom flex flex-1 shrink-0 items-end">
            <h3 className="themed-text text-body18XB">
              Visit community
              <span>
                <ArrowRightIcon className="h-[26px] w-[30px] -translate-x-[4px]" />
              </span>
            </h3>
          </div> */}
        </div>
      </div>
    </div>
  );
}
