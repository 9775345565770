import {
  ChannelCreateInput,
  ChannelGroupCreateInput,
  ChannelPayload,
  ChannelUpdateInput,
  Channel_Group_Types,
} from '@10x/foundation/types/graphql-schema';
import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

enableStaticRendering(typeof window === 'undefined');

import { ApiBase, CommonApiDataShapeType } from './ApiBase';

import { MessageModel } from './Message.model';

import type {
  BaseRepositoryResponse,
  IChannelRepository,
} from '@mainApp/src/repositories';
import { IChannelStore } from './Channel.store.types';

// import { CommunityModel } from './Community.model';
import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import { ChannelModel } from './Channel.model';
import { ChannelGroupModel } from './ChannelGroup.model';
import type { IToastStore } from './Toast.store';

@injectable()
export class ChannelStore extends ApiBase implements IChannelStore {
  repository: IChannelRepository;
  activeChannel: CommonApiDataShapeType<ChannelModel | null> =
    ChannelStore.generateCommonApiDataShape();

  channelGroups: CommonApiDataShapeType<ChannelGroupModel[] | null> =
    ChannelStore.generateCommonApiDataShape();

  get channelNormalGroups() {
    return {
      ...this.channelGroups,
      data: this.getGroupsByType(Channel_Group_Types.Group),
    };
  }
  get channelModerationGroups() {
    return {
      ...this.channelGroups,
      data: this.getGroupsByType(Channel_Group_Types.Moderation),
    };
  }
  get channelUngroupedGroups() {
    return {
      ...this.channelGroups,
      data: this.getGroupsByType(Channel_Group_Types.UngroupedChannel),
    };
  }

  get flattenGroupsChannels() {
    const groups = this.channelGroups.data || [];
    const flattennedChannels = groups
      .map((group) => group.channels)
      .flat()
      .filter((channelModel) => {
        return channelModel.serverData.name
          .toLowerCase()
          .includes(this.channelsSearchCriteria.toLowerCase());
      });
    return flattennedChannels;
  }

  channelsSearchCriteria = '';

  // TODO: maybe a dedicated message store? messages stuff is huge
  messages: CommonApiDataShapeType<MessageModel[] | null> =
    ChannelStore.generateCommonApiDataShape(true, []);

  messagesChunkAmount = 5;

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.channelRepository) repository: IChannelRepository
  ) {
    super(toastStore);

    this.repository = repository;

    makeObservable(this, {
      activeChannel: observable,
      channelsSearchCriteria: observable,
      channelGroups: observable,
      flattenGroupsChannels: computed,
      channelNormalGroups: computed,
      channelModerationGroups: computed,
      channelUngroupedGroups: computed,
      setChannelSearchCriteria: action,
      getChannelGroups: flow,
      getChannelBySlug: flow,
    });
  }

  *getChannelGroups(communityId: string, noCache?: boolean) {
    // Yield instead of await.
    const { data, error } = yield this.repository.getChannelGroups(
      communityId,
      noCache
    );

    if (error) {
      console.error(`get channel groups Error ->>> ${error}`);
    }

    // TODO: dependency injection of Models
    const result = data
      ? data.map((item: any) => {
          return new ChannelGroupModel(item.node).setChannels(
            item.node.channels,
            ChannelModel
          );
        })
      : [];

    this.channelGroups = {
      ...this.channelGroups,
      data: result,
      error,
      loading: false,
    };

    return result;
  }

  setChannelSearchCriteria(search = '') {
    this.channelsSearchCriteria = search;
  }

  private getGroupsByType(type: Channel_Group_Types) {
    if (!this.channelGroups.data?.length) return [];

    return this.channelGroups.data.filter(
      (channelGroup) => channelGroup.channelGroupType === type
    );
  }

  // TODO: check these generator type issue later

  *getChannelBySlug(
    communityId: string,
    slug: string
  ): Generator<Promise<ChannelModel>> {
    // not sure if the loading managing is useful here
    // @ts-ignore
    const { data, error } = yield this.repository.getChannelBySlug(
      communityId,
      slug
    ) as any;

    if (error) {
      throw `fetch channel by slug error: ${error}`;
    }

    return this.setActiveChannel(data, error);
  }

  setActiveChannel(data: ChannelPayload, error: string | null) {
    const channelModel = new ChannelModel(data);

    runInAction(() => {
      this.activeChannel = {
        data: channelModel,
        error,
        loading: false,
        meta: {},
      };
    });

    return channelModel;
  }

  async createChannel(
    communityId: string,
    channelGroupId: string,
    data: ChannelCreateInput
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    const response = await this.repository.createChannel(
      communityId,
      channelGroupId,
      data
    );
    if (response.data) {
      await this.getChannelGroups(communityId, true);
    }

    return response;
  }
  async updateChannel(
    communityId: string,
    id: string,
    payload: ChannelUpdateInput,
    // true to skip updating the active channel because updating another channel
    skipUpdate?: boolean
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    if (!skipUpdate) {
      this.activeChannel = {
        ...this.activeChannel,
        loading: true,
      };
    }

    const response = await this.repository.updateChannel(
      communityId,
      id,
      payload
    );

    if (response.data) {
      await this.getChannelGroups(communityId, true);
    }

    const { error } = response;
    if (error) {
      this.handleError('errors.updateChannelError', error.message);
    }

    if (!skipUpdate) {
      this.setActiveChannel(response.data as ChannelPayload, error);
    }

    return response;
  }

  async channelGroupMarkAllChannelsAsRead(
    communityId: string,
    groupId: string
  ) {
    return await this.repository.channelGroupMarkAllChannelsAsRead(
      communityId,
      groupId
    );
  }

  async removeChannel(
    communityId: string,
    channelGroupId: string,
    id: string
  ): Promise<BaseRepositoryResponse<ChannelPayload>> {
    const response = await this.repository.removeChannel(
      communityId,
      channelGroupId,
      id
    );
    if (response.data) {
      await this.getChannelGroups(communityId, true);
    }

    return response;
  }

  async createChannelGroup(
    communityId: string,
    payload: ChannelGroupCreateInput
  ) {
    const response = await this.repository.createChannelGroup(
      communityId,
      payload
    );

    if (response.data) {
      await this.getChannelGroups(communityId, true);
    }

    return response;
  }

  async removeChannelGroup(communityId: string, id: string): Promise<void> {
    await this.repository.removeChannelGroup(communityId, id);
  }

  updateChannelsOrderInGroup = async (
    communityId: string,
    channelGroupId: string,
    channelIds: string[]
  ) => {
    const { error } = await this.repository.updateChannelsOrderInGroup(
      communityId,
      channelGroupId,
      channelIds
    );

    if (error) {
      this.handleError('Update channels order', error.message);
      return error;
    }
  };
  updateChannelGroupsOrder = async (
    communityId: string,
    groupIds: string[]
  ) => {
    const { error } = await this.repository.updateChannelGroupsOrder(
      communityId,
      groupIds
    );

    if (error) {
      this.handleError('Update groups order', error.message);
      return error;
    }
  };
}
